"use strict";

var _interopRequireWildcard = require("@babel/runtime-corejs3/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));

var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));

var _lodash = _interopRequireDefault(require("lodash"));

var Misc = _interopRequireWildcard(require("@/helpers/Misc"));

var _state = _interopRequireDefault(require("@/libs/state"));

var _Captcha = _interopRequireDefault(require("@/components/Captcha"));

var _CommonLayout = _interopRequireDefault(require("@/components/startups/CommonLayout"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var state = (0, _state.default)();
var ctor = {
  components: {
    Captcha: _Captcha.default,
    StartupLayout: _CommonLayout.default
  },
  watch: {
    useLawnmower: function useLawnmower(newVal) {
      if (newVal && !this.channel) {
        this.channel = '#lawnmower';
      }
    }
  },
  data: function data() {
    return {
      network: null,
      channel: '',
      nick: '',
      password: '',
      showChannel: true,
      showPass: true,
      showNick: true,
      show_password_box: false,
      connectWithoutChannel: false,
      undernet: {
        showLogin: false,
        username: '',
        hideHost: true,
        totp: ''
      },
      useLawnmower: false
    };
  },
  computed: {
    greetingText: function greetingText() {
      var greeting = state.settings.startupOptions.greetingText;
      return typeof greeting === 'string' ? greeting : this.$t('start_greeting');
    },
    buttonText: function buttonText() {
      var greeting = state.settings.startupOptions.buttonText;
      return typeof greeting === 'string' ? greeting : this.$t('start_button');
    },
    readyToStart: function readyToStart() {
      var ready = !!this.nick;

      if (!this.connectWithoutChannel && !this.channel) {
        ready = false;
      } // Nicks cannot start with [0-9- ]
      // ? is not a valid nick character but we allow it as it gets replaced
      // with a number.


      if (!this.nick.match(/^[a-z_\\[\]{}^`|][a-z0-9_\-\\[\]{}^`|]*$/i)) {
        ready = false;
      }

      if (this.undernet.showLogin && (!this.undernet.username || !this.password)) {
        ready = false;
      }

      return ready;
    }
  },
  created: function created() {
    var _context;

    var options = state.settings.startupOptions;
    this.nick = this.processNickRandomNumber(Misc.queryStringVal('nick') || options.nick || '');
    if ((0, _indexOf.default)(_context = window.location.href).call(_context, 'lawnmower') > -1) this.useLawnmower = true;
    this.password = options.password || '';
    this.channel = decodeURI(window.location.hash) || options.channel || '';
    this.showChannel = typeof options.showChannel === 'boolean' ? options.showChannel : true;
    this.showNick = typeof options.showNick === 'boolean' ? options.showNick : true;
    this.showPass = typeof options.showPassword === 'boolean' ? options.showPassword : true;

    if (options.autoConnect && this.nick && this.channel) {
      this.startUp();
    }

    this.connectWithoutChannel = !!options.allowNoChannel;
  },
  methods: {
    readableStateError: function readableStateError(err) {
      return Misc.networkErrorMessage(err);
    },
    formSubmit: function formSubmit() {
      if (this.readyToStart) {
        this.startUp();
      }
    },
    startUp: function startUp() {
      var _this = this;

      var options = state.settings.startupOptions;
      var net;

      if (!this.network) {
        var netAddress = (0, _trim.default)(_lodash.default).call(_lodash.default, options.server);
        var netPort = options.port;
        var netTls = options.tls;

        if (this.useLawnmower) {
          netAddress = 'lawnmower.undernet.org';
          netPort = 6969;
          netTls = false;
        } // Check if we have this network already


        net = state.getNetworkFromAddress(netAddress); // If we retreived an existing network, update the nick+password with what
        // the user has just put in place

        if (net) {
          net.nick = this.nick;
          net.connection.password = this.generateServerPassword();
        } // If the network doesn't already exist, add a new one


        net = net || state.addNetwork('Network', this.nick, {
          server: netAddress,
          port: netPort,
          tls: netTls,
          password: this.generateServerPassword(),
          encoding: (0, _trim.default)(_lodash.default).call(_lodash.default, options.encoding),
          direct: !!options.direct,
          path: options.direct_path || '',
          gecos: options.gecos
        });
        this.network = net;
      } else {
        net = this.network;
      } // Only switch to the first channel we join if multiple are being joined


      var hasSwitchedActiveBuffer = false;
      var bufferObjs = Misc.extractBuffers(this.channel);
      (0, _forEach.default)(bufferObjs).call(bufferObjs, function (bufferObj) {
        var newBuffer = state.addBuffer(net.id, bufferObj.name);
        newBuffer.enabled = true;

        if (newBuffer && !hasSwitchedActiveBuffer) {
          state.setActiveBuffer(net.id, newBuffer.name);
          hasSwitchedActiveBuffer = true;
        }

        if (bufferObj.key) {
          newBuffer.key = bufferObj.key;
        }
      });
      net.ircClient.connect();

      var onRegistered = function onRegistered() {
        if (_this.$refs.layout) {
          _this.$refs.layout.close();
        }

        net.ircClient.off('registered', onRegistered);
        net.ircClient.off('close', onClosed);
      };

      var onClosed = function onClosed() {
        net.ircClient.off('registered', onRegistered);
        net.ircClient.off('close', onClosed);
      };

      net.ircClient.once('registered', onRegistered);
      net.ircClient.once('close', onClosed);
    },
    generateServerPassword: function generateServerPassword() {
      var _context2, _context3;

      if (!this.undernet.showLogin) {
        return '';
      }

      var pass = '';
      pass += this.undernet.hideHost ? '+x!' : '-x';
      pass += (0, _concat.default)(_context2 = (0, _concat.default)(_context3 = " ".concat(this.undernet.username, " ")).call(_context3, this.password, " ")).call(_context2, this.undernet.totp);
      return pass;
    },
    processNickRandomNumber: function processNickRandomNumber(nick) {
      // Replace ? with a random number
      var tmp = (nick || '').replace(/\?/g, function () {
        return Math.floor(Math.random() * 100).toString();
      });
      return (0, _trim.default)(_lodash.default).call(_lodash.default, tmp);
    }
  }
};
var _default = ctor;
exports.default = _default;
state.getStartups().undernet = ctor;